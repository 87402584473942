'use client';

import { useCallback, useContext, useReducer } from 'react';
import { useSearchParams, useParams } from 'next/navigation';
import qs from 'query-string';
import moment from 'moment';

import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { DATE_FORMAT } from 'constants/date';
import { ALLOWED_POINT_OF_SALE } from 'constants/constants';
import { useConfigSettings } from 'hooks/useConfigSettings';
import { PRICE_FILTER_FIX } from 'constants/featureToggleNames';
import { CurrencyContext } from 'context/CurrencyContext';

export const SEARCH_VALUES_ACTIONS = {
  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
  SET_DATE_RANGE: 'SET_DATE_RANGE',
  SET_MIN_PRICE: 'SET_MIN_PRICE',
  SET_MAX_PRICE: 'SET_MAX_PRICE',
  SET_PROPERTY_TYPE: 'SET_PROPERTY_TYPE',
  SET_TAGS: 'SET_TAGS',
  SET_AMENITIES: 'SET_AMENITIES',
  SET_NUMBER_OF_BEDROOMS: 'SET_NUMBER_OF_BEDROOMS',
  SET_NUMBER_OF_BATHROOMS: 'SET_NUMBER_OF_BATHROOMS',
  SET_SELECTED_LOCATION: 'SET_SELECTED_LOCATION',
  SET_SELECTED_GUESTS_COUNT: 'SET_SELECTED_GUESTS_COUNT',
  SET_SELECTED_ROOMS_COUNT: 'SET_SELECTED_ROOMS_COUNT',
  RESET_PRICE: 'RESET_PRICE',
  RESET_PROPERTY_TYPE: 'RESET_PROPERTY_TYPE',
  RESET_TAGS: 'RESET_TAGS',
  RESET_AMENITIES: 'RESET_AMENITIES',
  RESET_NUMBER_OF_BEDROOMS: 'RESET_NUMBER_OF_BEDROOMS',
  RESET_NUMBER_OF_BATHROOMS: 'RESET_NUMBER_OF_BATHROOMS',
  CLEAR_ALL: 'CLEAR_ALL',
};

class SearchValue {
  #configSettings;

  constructor({ search, id, configSettings }) {
    const params = qs.parse(search);
    this.#configSettings = configSettings;

    this.startDate = params.checkIn && moment(params.checkIn).isValid() ? moment(params.checkIn) : undefined;
    this.endDate = params.checkOut && moment(params.checkOut).isValid() ? moment(params.checkOut) : undefined;
    this.checkInDateLocalized =
      this.startDate && moment(this.startDate).isValid() ? moment(this.startDate).format(DATE_FORMAT) : undefined;
    this.checkOutDateLocalized =
      this.endDate && moment(this.endDate).isValid() ? moment(this.endDate).format(DATE_FORMAT) : undefined;
    this.listingId = id;
    this.minOccupancy = params.minOccupancy;
    this.rooms = params.rooms;
    this.city = params.city;
    this.country = params.country;
    this.currency = params.currency;
    this.minPrice = params.minPrice;
    this.maxPrice = params.maxPrice;
    this.propertyType = params.propertyType;
    this.tags = params.tags;
    this.includeAmenities = params.includeAmenities;
    this.numberOfBedrooms = params.numberOfBedrooms;
    this.numberOfBathrooms = params.numberOfBathrooms;
    this.pointofsale = ALLOWED_POINT_OF_SALE.includes(params.pointofsale) ? params.pointofsale : undefined;
    this.sortBy = params.sortBy;
    this.sortOrder = params.sortOrder;

    if (params.minOccupancy) {
      this.minOccupancy = Number(params.minOccupancy);
    }

    if (params.rooms) {
      this.rooms = Number(params.rooms);
    }
  }

  getParsedAmenities() {
    const amenities = this.includeAmenities?.split(',') || [];
    const amenityTypes = this.#configSettings.allAmenityTypes;
    return amenities.reduce((acc, amenity) => {
      // eslint-disable-next-line no-prototype-builtins
      if (amenityTypes.hasOwnProperty(amenity)) {
        acc.push(amenityTypes[amenity].value);
      }
      return acc;
    }, []);
  }

  getParsedPropertyTypes() {
    const propertyTypes = this.#configSettings.allPropertyTypes;
    const currentPropertyType = propertyTypes[this.propertyType];
    return currentPropertyType ? currentPropertyType.value : null;
  }

  getParsedTags() {
    return Array.isArray(this.tags) ? this.tags : this.tags?.split(',') || [];
  }
}

const initialState = (searchParams) => ({
  startDate: searchParams.startDate,
  endDate: searchParams.endDate,
  dateRange: { startDate: searchParams.startDate, endDate: searchParams.endDate },
  minPrice: searchParams.minPrice || null,
  maxPrice: searchParams.maxPrice || null,
  propertyType: searchParams.getParsedPropertyTypes(),
  tags: searchParams.getParsedTags(),
  amenities: searchParams.getParsedAmenities(),
  numberOfBedrooms: searchParams.numberOfBedrooms || 0,
  numberOfBathrooms: searchParams.numberOfBathrooms || 0,
  selectedLocation: { city: searchParams.city, country: searchParams.country },
  selectedGuestsCount: searchParams.minOccupancy || 1,
  selectedRoomsCount: searchParams.rooms || 1,
});

function reducer(state, action) {
  switch (action.type) {
    case SEARCH_VALUES_ACTIONS.SET_START_DATE:
      return { ...state, startDate: action.payload };
    case SEARCH_VALUES_ACTIONS.SET_END_DATE:
      return { ...state, endDate: action.payload };
    case SEARCH_VALUES_ACTIONS.SET_DATE_RANGE:
      return { ...state, dateRange: action.payload };
    case SEARCH_VALUES_ACTIONS.SET_MIN_PRICE:
      return { ...state, minPrice: action.payload };
    case SEARCH_VALUES_ACTIONS.SET_MAX_PRICE:
      return { ...state, maxPrice: action.payload };
    case SEARCH_VALUES_ACTIONS.SET_PROPERTY_TYPE:
      return { ...state, propertyType: action.payload };
    case SEARCH_VALUES_ACTIONS.SET_TAGS:
      return { ...state, tags: action.payload };
    case SEARCH_VALUES_ACTIONS.SET_AMENITIES:
      return { ...state, amenities: action.payload };
    case SEARCH_VALUES_ACTIONS.SET_NUMBER_OF_BEDROOMS:
      return { ...state, numberOfBedrooms: action.payload };
    case SEARCH_VALUES_ACTIONS.SET_NUMBER_OF_BATHROOMS:
      return { ...state, numberOfBathrooms: action.payload };
    case SEARCH_VALUES_ACTIONS.SET_SELECTED_LOCATION:
      return { ...state, selectedLocation: action.payload };
    case SEARCH_VALUES_ACTIONS.SET_SELECTED_GUESTS_COUNT:
      return { ...state, selectedGuestsCount: action.payload };
    case SEARCH_VALUES_ACTIONS.SET_SELECTED_ROOMS_COUNT:
      return { ...state, selectedRoomsCount: action.payload };
    case SEARCH_VALUES_ACTIONS.RESET_PRICE:
      return { ...state, minPrice: null, maxPrice: null };
    case SEARCH_VALUES_ACTIONS.RESET_PROPERTY_TYPE:
      return { ...state, propertyType: null };
    case SEARCH_VALUES_ACTIONS.RESET_TAGS:
      return { ...state, tags: [] };
    case SEARCH_VALUES_ACTIONS.RESET_AMENITIES:
      return { ...state, amenities: [] };
    case SEARCH_VALUES_ACTIONS.RESET_NUMBER_OF_BEDROOMS:
      return { ...state, numberOfBedrooms: 0 };
    case SEARCH_VALUES_ACTIONS.RESET_NUMBER_OF_BATHROOMS:
      return { ...state, numberOfBathrooms: 0 };
    case SEARCH_VALUES_ACTIONS.CLEAR_ALL:
      return {
        ...state,
        tags: [],
        amenities: [],
        maxPrice: null,
        minPrice: null,
        numberOfBathrooms: 0,
        numberOfBedrooms: 0,
        propertyType: null,
      };
    default:
      return state;
  }
}

function useSearchValues() {
  const searchParams = useSearchParams();
  const search = searchParams.toString();
  const { id } = useParams();
  const configSettings = useConfigSettings();
  return new SearchValue({ search, id, configSettings });
}

export function useSearchValuesForm() {
  const searchParams = useSearchValues();
  const [state, dispatch] = useReducer(reducer, initialState(searchParams));
  const [, isPriceFilterFixEnabled] = useFeatureToggle(PRICE_FILTER_FIX);
  const { defaultCurrency } = useContext(CurrencyContext);

  const getFilterParams = useCallback(() => {
    return {
      currency: defaultCurrency,
      tags: state.tags.toString(),
      includeAmenities: state.amenities.toString(),
      maxPrice: state.maxPrice,
      minPrice: state.minPrice,
      numberOfBathrooms: state.numberOfBathrooms,
      numberOfBedrooms: state.numberOfBedrooms,
      propertyType: state.propertyType,
    };
  }, [defaultCurrency, state]);

  return {
    searchParams,
    ...state,
    dispatch,
    isPriceFilterFixEnabled,
    getFilterParams,
  };
}

export default useSearchValues;
