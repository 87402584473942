import { useMemo } from 'react';
import reduce from 'lodash/reduce';

import { getAmenitiesTypes, getNewAmenities, getNewPropertyTypes, getPropertyTypes } from 'constants/constants';

export const useAmenities = () => {
  // fix to update the hook when the amenity changes
  const amenityTranslation = getAmenitiesTypes().SWIMMING_POOL.label;

  return useMemo(() => {
    const _legacyAmenityTypes = getAmenitiesTypes();
    const _newAmenityTypes = getNewAmenities();
    const _amenityTypes = { ..._legacyAmenityTypes, ..._newAmenityTypes };
    return {
      newAmenityTypes: _newAmenityTypes,
      allAmenityTypes: _amenityTypes,
    };
  }, [amenityTranslation]);
};

export const getItemTranslation = ({ allTranslations, selectedValue }) => {
  return reduce(
    allTranslations,
    (acc, { value, label }) => {
      if (value.toLowerCase() === selectedValue.toLowerCase()) {
        acc = label;
      }
      return acc;
    },
    ''
  );
};

export const usePropertyTypes = () => {
  // fix to update the hook when the amenity changes
  const propertyTypeTranslation = getPropertyTypes().APARTMENT.label;

  return useMemo(() => {
    const _legacyPropertyTypes = getPropertyTypes();
    const _newPropertyTypes = getNewPropertyTypes();
    const _propertyTypes = { ..._legacyPropertyTypes, ..._newPropertyTypes };
    return {
      newPropertyTypes: _newPropertyTypes,
      allPropertyTypes: _propertyTypes,
    };
  }, [propertyTypeTranslation]);
};

export const useConfigSettings = () => {
  const { allPropertyTypes, newPropertyTypes } = usePropertyTypes();
  const { allAmenityTypes, newAmenityTypes } = useAmenities();

  return {
    allPropertyTypes,
    newPropertyTypes,
    allAmenityTypes,
    newAmenityTypes,
  };
};
