import { useMutation, useQuery } from 'react-query';

import getCurrencyRate from 'api/currency/currencyRate';

import { QUERY_IDS } from 'api';

const { GET_CURRENCY_RATE } = QUERY_IDS;

export const useGetCurrencyRateQuery = ({ onSuccessHandler, from, to }) => {
  const enabled = from && to && from !== to;
  const {
    data,
    isLoading,
    isSuccess,
    isError,
    status,
  } = useQuery(
    [`${GET_CURRENCY_RATE}-FROM-${from}-TO-${to}`],
    () => getCurrencyRate({ from, to }),
    {
      onSuccess: onSuccessHandler,
      enabled,
    }
  );

  return {
    data,
    status,
    isLoading,
    isSuccess,
    isError,
  };
}

const useGetCurrencyRate = ({ onSuccessHandler, from, to }) => {
  const {
    mutateAsync,
    data,
    isLoading,
    isSuccess,
    isError,
    status,
  } = useMutation(() => getCurrencyRate({ from, to }), {
    mutationKey: [`${GET_CURRENCY_RATE}-FROM-${from}-TO-${to}`],
    onSuccess: onSuccessHandler,
  });


  return {
    fetchHandler: mutateAsync,
    data,
    status,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useGetCurrencyRate;
