'use client';

import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import useGetCurrencyRate from 'hooks/useGetCurrencyRate';
import defaultWebsiteCurrency from 'constants/defaultWebsiteCurrency';

const CurrencyContext = createContext({
  defaultCurrency: defaultWebsiteCurrency,
  selectedCurrency: defaultWebsiteCurrency,
  setSelectedCurrency: () => { },
  currencyRate: {},
  setCurrencyRate: () => { },
});

const CurrencyContextProvider = ({ children }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(defaultWebsiteCurrency);
  const [currencyRate, setCurrencyRate] = useState({});
  const { enginesData: { currency: defaultCurrency = defaultWebsiteCurrency } = {} } =
    useContext(WebsiteSettingsContext);


  const { fetchHandler: getCurrencyRate, status } = useGetCurrencyRate({
    onSuccessHandler: (rate) => {
      setCurrencyRate({ ...currencyRate, [selectedCurrency]: rate.rate });
    },
    from: defaultCurrency,
    to: selectedCurrency,
  });
  const value = useMemo(
    () => ({
      defaultCurrency,
      selectedCurrency,
      setSelectedCurrency,
      currencyRate,
      setCurrencyRate,
      status,
    }),
    [defaultCurrency, selectedCurrency, currencyRate, status]
  );

  useEffect(() => {
    setSelectedCurrency(defaultCurrency);
  }, [defaultCurrency]);

  useEffect(() => {
    async function getCurrency() {
      await getCurrencyRate();
    }

    if (defaultCurrency !== selectedCurrency && selectedCurrency && !currencyRate[selectedCurrency]) {
      getCurrency();
    }
  }, [selectedCurrency, currencyRate, defaultCurrency, getCurrencyRate]);

  return <CurrencyContext.Provider value={value}>{children}</CurrencyContext.Provider>;
};

export { CurrencyContext };
export default CurrencyContextProvider;
