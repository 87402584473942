/* eslint-disable import/no-unresolved */
import { defineRouting } from 'next-intl/routing';
import { createNavigation } from 'next-intl/navigation';

const DEFAULT_LANGUAGE = 'en';

const LANGUAGE_CODES = {
  DE: 'de',
  EL: 'el',
  EN: 'en',
  ES: 'es',
  FR: 'fr',
  ID: 'id',
  IT: 'it',
  JA: 'ja',
  KO: 'ko',
  PL: 'pl',
  PT: 'pt',
  RO: 'ro',
  ZH: 'zh',
  NL: 'nl',
};
export const shortLanguageList = [
  LANGUAGE_CODES.DE,
  LANGUAGE_CODES.EL,
  LANGUAGE_CODES.EN,
  LANGUAGE_CODES.ES,
  LANGUAGE_CODES.FR,
  LANGUAGE_CODES.ID,
  LANGUAGE_CODES.IT,
  LANGUAGE_CODES.JA,
  LANGUAGE_CODES.KO,
  LANGUAGE_CODES.PL,
  LANGUAGE_CODES.PT,
  LANGUAGE_CODES.RO,
  LANGUAGE_CODES.ZH,
  LANGUAGE_CODES.NL,
];

export const routing = defineRouting({
  locales: shortLanguageList,
  defaultLocale: DEFAULT_LANGUAGE,
  localeCookie: false,
});

export const { Link, redirect, usePathname, useRouter, getPathname } = createNavigation(routing);
